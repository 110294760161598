<template>
  <div class="wrapper">
    <b-card no-body bg-variant="dark">
      <div class="animated fadeIn">
        <b-row>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/isu_transparan_logo.png" style="width: 200px;">
              <blockquote class="card-blockquote">
                <br>
                <br>
                <br>
                <footer>
                  <cite title="İstinye Üniversitesi"><strong>İstinye Üniversitesi</strong> / İstanbul</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/mlpcaregeneral.jpeg" style="width: 200px;">
              <blockquote class="card-blockquote">
                <br>
                <footer>
                  <cite title="MlpCare"><strong>MLPCARE Grubu</strong></cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/ulusliv.png" style="width: 100px;">
              <blockquote class="card-blockquote">
                <br>
                <br>
                <br>
                <footer>
                  <cite title="Liv Hospital / Ulus"><strong>Ulus Liv Hastanesi</strong> / İstanbul</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/ulusliv.png" style="width: 100px;">
              <blockquote class="card-blockquote">
                <br>
                <br>
                <br>
                <footer>
                  <cite title="Liv hospital / Bahçeşehir"><strong>İstinye Üniversitesi Bahçeşehir Liv Hastanesi</strong> / İstanbul</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/iau_medicalparkflorya.jpg" style="width: 100px;">
              <blockquote class="card-blockquote">
                <br>
                <footer>
                  <cite title="İAU Medical Park"><strong>İstanbul Aydın Üniversitesi Medical Park Hastanesi</strong> / İstanbul</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/mlpcare_bursa.jpg" style="width: 100px;">
              <blockquote class="card-blockquote">
                <br>
                <footer>
                  <cite title="Bursa Medical Park"><strong>Bursa Medical Park Hastanesi</strong> / Bursa</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/baugoztepe.jpg" style="width: 100px;">
              <blockquote class="card-blockquote">
                <br>
                <footer>
                  <cite title="BAU Göztepe Medical Park"><strong>Bahçeşehir Üniversitesi Tıp Fakültesi Göztepe Medical Park Hastanesi</strong> / İstanbul</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/altinbasbahcelievlermedicalpark.jpg" style="width: 100px;">
              <blockquote class="card-blockquote">
                <br>
                <footer>
                  <cite title="AU Bahçelievler Medical Park"><strong>Altınbaş Üniversitesi Tıp Fakültesi Bahçelievler Medical Park Hastanesi</strong> / İstanbul</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
          <b-col sm="6" md="3">
            <b-card class="bg-white text-center" style="height: 80%;">
              <img src="../../../static/hospital-logos/mlpcarekocaeli.jpg" style="width: 100px;">
              <blockquote class="card-blockquote">
                <br>
                <footer>
                  <cite title="Kocaeli Medical Park"><strong>Kocaeli Medical Park Hastanesi</strong> / Kocaeli</cite>
                </footer>
              </blockquote>
            </b-card>
          </b-col>
        </b-row>
        <br>
        <web-bottom></web-bottom>
      </div>
    </b-card>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
import { mapGetters } from 'vuex';
export default {
  name: 'Contact',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WebBottom
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style>


</style>

